<template>
  <div class="DataCenter_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
      "
    >
      <div style="display: flex; justify-content: space-between">
        <div class="page_title">{{ language.DataStatistics }}</div>
        <div style="display: flex; justify-content: center; align-items: center">
          <el-dropdown @command="changeCurrency">
            <span class="el-dropdown-link" style="display: inline-block; font-size: 24px">
              {{ Currency }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in options2"
                :key="item.type_name"
                :command="item.type_name"
                style="font-size: 24px"
                >{{ item.type_name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div style="display: flex; margin-top: 30px">
        <div style="display: flex">
          <div class="input_box">
            <div class="block">
              <el-date-picker
                v-model="Select_Month"
                type="daterange"
                :range-separator="language.to"
                :start-placeholder="language.Start_Date"
                :end-placeholder="language.End_Date"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="input_box">
            <el-button type="primary" @click="SearchBtn()">{{
              language.Search
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 10px;
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
      "
    >
      <div style="display: flex">
        <!-- 总交易额 -->
        <div class="box">
          <div>{{ language.TotalAmount }}</div>
          <div>{{ totalMoney }}{{ Currency }}</div>
        </div>
        <!-- 总交易笔数 -->
        <div class="box">
          <div>{{ language.TotalNumberOfTransactions }}</div>
          <div>{{ totalNumber }}</div>
        </div>
        <!-- 单日最高交易 -->
        <div class="box">
          <div>{{ language.Maximumamountperday }}</div>
          <div>{{ maxMoney }}{{ Currency }}</div>
        </div>
        <!-- 单日最低交易 -->
        <div class="box">
          <div>{{ language.Minimumamountperday }}</div>
          <div>{{ minMoney }}{{ Currency }}</div>
        </div>
        <!-- 单日最高交易笔数 -->
        <div class="box">
          <div>{{ language.Maximumnumberoftransactionsperday }}</div>
          <div>{{ maxNumber }}</div>
        </div>
        <!-- 单日最低交易笔数 -->
        <div class="box">
          <div>{{ language.Minimumnumberoftransactionsperday }}</div>
          <div>{{ minNumber }}</div>
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 10px;
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
        height: calc(100% - 327px);
        display: flex;
        overflow: hidden;
      "
    >
      <div id="main" style="flex: 1"></div>
    </div>
  </div>
</template>

<script>
import payment from "../../common/json/payment.json";
import { dashboard } from "../../api/index.js";
export default {
  data() {
    return {
      language: "",
      Select_Month: "",
      option: "",
      maxMoney: "0.00", //单日最高交易金额
      maxNumber: 0, //单日最高交易笔数
      minMoney: "0.00", //单日最低交易金额
      minNumber: 0, //单日最低交易笔数
      totalMoney: "0.00", //总交易金额
      totalNumber: 0, //总交易笔数
      searchtime: "", //后台需要的时间
      options2: [],
      Currency: "CNY", //币种筛选
      userinfo: "",
    };
  },

  mounted() {
    // 在通过mounted调用即可
    // this.echartsInit()
    this.changelanguage();
  },

  created() {
    this.options2 = payment.otherCurrencylist;
    // console.log(payment.otherCurrencylist);
    let time = new Date();
    this.searchtime =
      this.$getNowFormatDate.getNowFormatDate1(time) +
      " 00:00:00" +
      " - " +
      this.$getNowFormatDate.getNowFormatDate(time) +
      " 23:59:59";
    this.Select_Month = [
      this.$getNowFormatDate.getNowFormatDate1(time) + " 00:00:00",
      this.$getNowFormatDate.getNowFormatDate(time) + " 23:59:59",
    ];
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (this.userinfo.currency != "all") {
      this.Currency = this.userinfo.currency;
      for (let i = this.options2.length - 1; i >= 0; i--) {
        if (this.options2[i].label != this.userinfo.currency) {
          this.options2.splice(i, 1);
        }
      }
    }
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Currency() {
      this.init();
    },
  },

  methods: {
    // 获取数据
    init() {
      let data = {
        timeStr: this.searchtime,
        currency: this.Currency,
      };
      dashboard({}, data).then((res) => {
        if (res.code == 1) {
          (this.maxMoney = res.data.info.maxMoney), //单日最高交易金额
            (this.maxNumber = res.data.info.maxNumber), //单日最高交易笔数
            (this.minMoney = res.data.info.minMoney), //单日最低交易金额
            (this.minNumber = res.data.info.minNumber), //单日最低交易笔数
            (this.totalMoney = res.data.info.totalMoney), //总交易金额
            (this.totalNumber = res.data.info.totalNumber), //总交易笔数
            this.echartsInit(res.data.list);
        }
      });
    },
    // 切换币种
    changeCurrency(command) {
      this.Currency = command;
    },
    // 获取本周
    getFirstDayOfWeek() {
      var oneDayTime = 24 * 60 * 60 * 1000;
      let starttime = new Date(this.searchtime.split(" - ")[0]);
      let endtime = new Date(this.searchtime.split(" - ")[1]);
      let data = {};
      let list1 = [];
      let list2 = [];
      let list3 = [];
      let date = new Date(endtime);
      for (let i = 0; i < Math.ceil((endtime - starttime) / oneDayTime); i++) {
        let year = new Date(date - oneDayTime * i).getFullYear(); //获取完整的年份(4位)
        let month = new Date(date - oneDayTime * i).getMonth() + 1;
        let strDate = new Date(date - oneDayTime * i).getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (strDate < 10) {
          strDate = "0" + strDate;
        }
        list1.unshift(year + "-" + month + "-" + strDate);
        list2.unshift(0);
        list3.unshift(0);
      }
      data = {
        list1,
        list2,
        list3,
      };
      return data;
    },
    // 搜索
    SearchBtn() {
      if (typeof this.Select_Month[0] == "string") {
        this.searchtime = this.Select_Month[0] + " - " + this.Select_Month[1];
      } else {
        this.searchtime =
          this.$getNowFormatDate.getNowFormatDate(this.Select_Month[0]) +
          " 00:00:00 - " +
          this.$getNowFormatDate.getNowFormatDate(this.Select_Month[1]) +
          " 23:59:59";
      }
      this.init();
    },
    echartsInit(e) {
      let data = this.getFirstDayOfWeek();
      for (let i = 0; i < e.X.length; i++) {
        for (let j = 0; j < data.list1.length; j++) {
          if (data.list1[j] == e.X[i]) {
            data.list2[j] = e.Y_Money[i];
            data.list3[j] = e.Y_Number[i];
          }
        }
      }
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      this.option = {
        grid: {
          bottom: 20,
          left: 70,
          right: 70,
        },
        tooltip: {
          textStyle: {
            fontSize: 21, // 字体大小
          },
        },
        legend: {
          data: [this.language.Transaction_Amount, this.language.Numberoftransactions],
          left: 10,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: false },
            // prettier-ignore
            data: data.list1,
          },
        ],
        yAxis: [
          {
            name: this.language.Transaction_Amount,
            type: "value",
            splitNumber: 4, //段数是4
            axisLabel: {
              textStyle: {
                fontSize: 14,
                fontWeight: 800,
                color: "#000",
              },
              formatter: function (value) {
                if (value >= 1000 && value < 10000) {
                  value = value / 1000 + "K";
                } else if (value >= 10000) {
                  value = value / 10000 + "W";
                }
                return value;
              },
            },
            // interval: true,
          },
          {
            type: "value",
            name: this.language.Numberoftransactions,
            splitNumber: 4, //段数是4
          },
        ],
        series: [
          {
            name: this.language.Transaction_Amount,
            type: "line",
            // prettier-ignore
            data: data.list2,
          },
          {
            name: this.language.Numberoftransactions,
            type: "line",
            yAxisIndex: 1,
            // prettier-ignore
            data: data.list3,
          },
        ],
      };
      this.$echarts.init(document.getElementById("main")).setOption(this.option);
    },
    changelanguage() {
      this.language = this.$units();
      this.init();
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.DataCenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_box {
  margin-right: 30px;
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.box:not(:first-child) {
  margin-left: 30px;
  border-left: 1px solid #efefef;
}
</style>
